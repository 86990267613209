import React, {useState} from 'react';
import logo from './logo.png';
import poppyhouse from './poppy_house.png';
import './App.css';

const sections = [
  {name: "about us", id: "about-us", content: "Tinkly Barter is a small toy company based in Berkeley, California. We design and manufacture our products at our Berkeley workshop.", link: {}},
   {name: "products", id: "products", content: "Meet Benny, perhaps *the* world's Most stinky, lumpy, snaggle-toothed dog.", link: {text: "Benny World is here!", href: "https://www.bennythedogworld.com"}},
    {name: "contact us", id: "contact", content: "We can be reached at info at tinklybarter.com. We aim to get back to you within 24 hours, Monday through Friday.", link: {}}
];

const VALUES_SHORT = [
{
  header: "Accountability", 
  imgUrl: "https://firebasestorage.googleapis.com/v0/b/coolnews-ebc9d.appspot.com/o/images%2Ftinklybarterco%2FBlue%20glorb%20.png?alt=media&token=bacd7f72-8bc0-464e-9116-3068c0dfc388",
  bullets: [
      "Here for the long term", "Safety, quality and environmental concerns come first", "Accountability for the products we put out into the world"
    ],
    color: "green",
},
{
  header: "Detail", 
  imgUrl: "https://firebasestorage.googleapis.com/v0/b/coolnews-ebc9d.appspot.com/o/images%2Ftinklybarterco%2FPink%20glorb%20.png?alt=media&token=4e8b49e0-82cb-409f-9e3a-e4c907c5225c",
  bullets: [
        "We deliberate over the tiniest of details, of every toy we produce - because we know children notice & care",
        "Attention to the details does not mean perfection - we know we won't always get it 100% right - we strongly encourage any and all feedback"
    ],
      color: "pink",
},
{
  header: "Quality", 
  imgUrl: "https://firebasestorage.googleapis.com/v0/b/coolnews-ebc9d.appspot.com/o/images%2Ftinklybarterco%2FYellow%20glorb.png?alt=media&token=db086888-1f14-457c-a81d-30ac70340125",
  bullets: [
      "Toys built to last a lifetime and beyond",
      "Use of the highest quality materials, suitable to the task at hand", 
      "Use of eco-friendly materials - while also considering quality, durability, aesthetics and cost, among other considerations - keeping in mind that's not always 'natural' materials"
    ],
      color: "blue",
},{
  header: "Silliness", 
    imgUrl: "https://firebasestorage.googleapis.com/v0/b/coolnews-ebc9d.appspot.com/o/images%2Ftinklybarterco%2FPurple%20glorb%20.png?alt=media&token=555c7d2b-2f67-42ab-b203-3e59530bceb1",
  bullets: [
      "Complex characters with rich backgrounds and stories to tell",
      "Inspiring children to use their imaginations, to create and above all, to be very silly!"
],
  color: "purple",
},

];


const App = ()  => {
  const [selectedSection, setSelectedSection] = useState(null);
    const [selectedSectionID, setSelectedSectionID] = useState(null)
  const [selectedSectionContent, setSelectedSectionContent] = useState(null);
  const [selectedSectionLink, setSelectedSectionLink] = useState({});
   const [selectedSectionItems, setSelectedSectionItems] = useState([]);

 
  const toggleShowSection = (sectionName, sectionContent, sectionLink, sectionID) => {
    setSelectedSection(sectionName);
      setSelectedSectionLink(sectionLink);
    setSelectedSectionContent(sectionContent);
       setSelectedSectionID(sectionID);

       if (sectionID === "about-us") {
        setSelectedSectionItems(VALUES_SHORT);
       } else {
             setSelectedSectionItems([]);
       }
  
  };  

    const getPageSections = () => {
       return selectedSectionItems.map((v) => {
                    return (
                        <div className="page-section-item-container">
                              
                                <div className="section-content">
                                  
                                <h2 className="section-header">{v.header.toUpperCase()}</h2>
                                <hr className={`bullet-line-top bullet-${v.color}`}></hr>
                                   <hr className={`bullet-line-bottom bullet-${v.color}`}></hr>
                                    <ul>
                                    {v.bullets.map((b) => {
                                        return (
                                            <li>{b}</li>
                                        )
                                    })}
                                    </ul>
                            </div>
                        </div>
                    )
        })
    }
  const getSections = () => {
    return sections.map((s) => {

     

      return (
        <div key={s.id}>
          <h2 className={s.id === selectedSection ? "highlight-section" : "reg-section"} onClick={() => {toggleShowSection(s.name, s.content, s.link, s.id)}}>
            {s.name}
            </h2>
          
            </div>
      );
    })
  } 
  return (
    <div className="App">
      <header className="App-header">
        <div className="header-container">
            <img src={logo} className="App-logo" alt="logo" />
                  <img src={poppyhouse} className="poppyhouse" alt="logo" />
            <a href="/">
            <h1>  
              Tinkly Barter
            </h1> 
            </a>
        </div>
        
      </header>
       <div className="main-section-col">
          <div className="main-section-row">
              {getSections()}
          </div>
           <div className="main-section-row-content">
          {!!selectedSection &&
              <div className="header-container-sm-screen header-container">
                <img src={logo} className="App-logo" alt="logo" />
                      <img src={poppyhouse} className="poppyhouse" alt="logo" />
                <a href="/">
              
            </a>
        </div>
          }
          {selectedSectionContent && 
            <p className="section-content-txt"> {selectedSectionContent}</p>       
        }

         {selectedSectionLink && 
          <a target="_blank" href={selectedSectionLink.href}>{selectedSectionLink.text}</a>
         }
         {selectedSectionItems && getPageSections()}
        
         </div>
        </div>
      <footer><p className='montserrat'>copyright © 2024 tinkly barter</p></footer>
    </div>
  );
}

export default App;
